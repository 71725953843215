import orgServices from '../../../services/notisphere/organization'


export default {
    data() {
        return {
            barOptions: {
                colors: ['#215b85', '#f16876'],
                chart: {
                    type: 'bar',
                    height: 160,
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '70%',
                        endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: true
                },
                yaxis: {
                    title: {
                        text: 'Number Of Accounts',
                        align: 'left',
                        floating: false,
                        style: {
                            fontSize: '12px',
                            fontWeight: '100',
                            fontFamily: '\'Rubik\', sans-serif',
                            color: '#888888',
                            opacity: 1
                        },
                    },
                },
            },
            barOptions1: {
                colors: ['#31CBC5', '#839fb9'],
                chart: {
                    type: 'bar',
                    height: 160,
                    stacked: true,
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '40%',
                        endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: true
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },

                yaxis: {
                    title: {
                        text: 'Recall Responses',
                        align: 'left',
                        floating: true,
                        stacked: 'true',
                        style: {
                            fontSize: '12px',
                            fontWeight: '100',
                            fontFamily: '\'Rubik\', sans-serif',
                            color: '#888888',
                            opacity: 1
                        },
                    },
                },
                fill: {
                    opacity: 1
                },
            },
            lineOptions: {
                chart: {
                    height: 160,
                    type: 'line',
                    zoom: {
                        enabled: false
                    },
                    toolbar: {
                        show: false
                    }
                },
                colors: ['#215b85', '#f16876'],
                dataLabels: {
                    enabled: true
                },
                stroke: {
                    curve: 'straight',
                    show: true,
                    lineCap: 'butt',
                    width: 5,
                    dashArray: 0,
                },
                yaxis: {
                    title: {
                        text: 'Registered Users',
                        align: 'left',
                        style: {
                            fontSize: '12px',
                            fontWeight: '100',
                            fontFamily: '\'Rubik\', sans-serif',
                            color: '#888888',
                            opacity: 1
                        },
                    },
                },

            },
            UniqueUserlineOptions: {
                chart: {
                    height: 160,
                    type: 'line',
                    zoom: {
                        enabled: false
                    },
                    toolbar: {
                        show: false
                    }
                },
                colors: ['#215b85', '#f16876'],
                dataLabels: {
                    enabled: true
                },
                stroke: {
                    curve: 'straight',
                    show: true,
                    lineCap: 'butt',
                    width: 5,
                    dashArray: 0,
                },
                yaxis: {
                    title: {
                        text: 'Logged in Users',
                        align: 'left',
                        style: {
                            fontSize: '12px',
                            fontWeight: '100',
                            fontFamily: '\'Rubik\', sans-serif',
                            color: '#888888',
                            opacity: 1
                        },
                    },
                },

            },
            barSeries: [],
            barSeries1: [],
            lineSeries: [],
            UniqueUserLineSeries: [],
            organizations: [],
            organization_id: null
        }
    },
    mounted() {
        this.fetchOrgMeta()
    },
    created() {
        this.getChartData()
    },
    methods: {
        getChartData() {
            orgServices.findKPIMeta(this.organization_id).then(resp => {
                if (!resp.error) {
                    this.barOptions = {
                        ... this.barOptions, ...{
                            xaxis: { categories: resp.data.d.barResponse.categories }
                        }
                    }
                    this.barSeries = resp.data.d.barResponse.series

                    this.barOptions1 = {
                        ... this.barOptions1, ...{
                            xaxis: { categories: resp.data.d.byMonthResponse.categories }
                        }
                    }
                    this.barSeries1 = resp.data.d.byMonthResponse.series

                    this.lineOptions = {
                        ...this.lineOptions, ...{
                            xaxis: { categories: resp.data.d.lineResponse.categories }
                        }
                    }
                    this.lineSeries = resp.data.d.lineResponse.series

                    this.UniqueUserlineOptions = {
                        ... this.UniqueUserlineOptions, ...{
                            xaxis: {
                                categories: resp.data.d.uniqueUserLineResponse.categories
                            }
                        }
                    }
                    this.UniqueUserLineSeries = resp.data.d.uniqueUserLineResponse.series
                }
            })
        },
        fetchOrgMeta() {
            orgServices.findMeta().then(resp => {
                if (!resp.error) {
                    const orgs = resp.data.d
                    this.organizations = orgs.filter(o => o.id !== 1)  // as per NOT-1368 removed NOTISPHERE admin from list
                }
            })
        },
    }
}